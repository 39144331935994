.commander-search-bar {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    width: 100%;
}

.commander-search-bar-icon {
    position: absolute;
    padding: 10px;
    min-width: 40px;
}

.commander-search-bar {
    &__input {
        border: 1px #cccccc solid;
        border-radius: 5px;
        padding: 5px 0px 5px 40px;
        width: 100%;
    }

    &__input:focus {
        outline: none !important;        
        box-shadow: 0 0 8px #719ECE;
    }
}

.command-card .command-paragraph {
    margin-top: 0px;
    margin-bottom: 0px;
    clear: both;
}

.command-pointer:hover {
    cursor: pointer;
}

.command-block {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #eff0f2;
    border-radius: 5px;
    border: 1px #cccccc solid;
}

.command-line {
    font-size: 0.9em;
    padding: 8px 0px 8px 16px;
}

.command-copy-button {
    border: none;
    border-radius: 5px;
    padding: 8px 16px 8px 16px;
}

.command-copy-button:hover {
    background: #d9dfeb;
    color: #333333;
}

.command-copy-button:focus{
    border: none;
    outline: none;
}

.command-copy-icon {
    font-weight: lighter;
}

@media only screen and (max-width: 768px) {
    .command-copy-button {
        display: none;
    }
}
