h1 {
    font-size: 1.7rem;
}

h2 {
    font-size: 1.3rem;
}

.container {
    max-width: 960px;
    padding: 0 1.5rem;
}

.main section {
    margin-bottom: 2rem;
}

.main__inner {
    padding: 2rem 0;
}

@media screen and (min-width: 768px) {
    h2 {
        font-size: 2rem;
    }

    .container {
        padding: 0 2rem;
    }

    .main section {
        margin-bottom: 3.5rem;
    }
}
