.blurb {
    padding: 2.5rem 0;
    background: #f1f4f8;

    &__avatar {
        display: block;
        border: 0.1875rem solid;
    }

    @media screen and (min-width: 768px) {
        .h1 {
            font-size: 3rem;
        }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__avatar {
            max-height: none;
            max-width: 18.75rem;
            margin-left: 2rem;
        }
    }

    @media screen and (max-width: 767px) {
        .container {
            justify-content: center;
            align-content: center;
            align-items: center;
        }

        &__avatar {
            max-height: 12.5rem;
            margin: 1.5rem auto 0;
        }
    }
}
