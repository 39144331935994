@import 'variables.scss';


html {
    font-size: 100%;
}

body {
    margin: 0;
    font-size: 1rem;
    color: #333;
    font-family: 'Google Sans','Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    // font-family: 'Jost', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
      
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*, ::after, ::before {
    box-sizing: border-box;
}